export default [
	{
		title: 'Internal checks',
		description:
			"All landlords and their listings are thoroughly checked by our team before going live. We collect contact information and ID as well as proof of address for all properties. We also ensure that listings have accurate information displayed so that you know exactly what you're getting!",
		image: 'https://storage.googleapis.com/digsconnect-imgix/static/safety/magnifying-glass.png',
	},
	{
		title: 'Trusted landlords',
		description:
			'Look out for the blue Trusted Landlord badge on a listing! This is a landlord that has placed at least one tenant through DigsConnect before. We know you can trust them.',
		image: 'https://storage.googleapis.com/digsconnect-imgix/static/safety/trusted-landlords.png',
	},
	{
		title: 'Listing rankings',
		description:
			'Detail-rich properties with landlords that are highly verified will rank higher on our platform. The properties that come up first in your search results are the ones we trust the most!',
		image: 'https://storage.googleapis.com/digsconnect-imgix/static/safety/listing-rankings.png',
	},
	{
		title: 'Dedicated support agents',
		description:
			'Once you have made an enquiry to a property, you are assigned a dedicated support agent who will guide you through the DigsConnect process. We are always there to help if you need it.',
		image: 'https://storage.googleapis.com/digsconnect-imgix/static/safety/bx_support.png',
	},
	{
		title: 'Verified phone numbers',
		description:
			'All users on our platform, both tenants and landlords, are required to verify their phone number with an OTP on sign up.',
		image: 'https://storage.googleapis.com/digsconnect-imgix/static/safety/verified.png',
	},
	{
		title: 'DigsConnect inbox',
		description:
			"This is our own integrated chat system that allows you to speak to a landlord and a support agent, all in one chat. This also means you don't have to move to personal communication channels.",
		image: 'https://storage.googleapis.com/digsconnect-imgix/static/safety/messge.png',
	},
];
