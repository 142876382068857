import React from 'react';
import theme from 'config/theme';
import safetyConfig from 'config/safety';
import styled from 'styled-components';
import urlUtils from 'utils/urlUtils';

import { SafetyGridContainer } from '../styles';

const { colors } = theme;

const SafetyGrid = () => (
	<SafetyGridContainer>
		{safetyConfig.map(({ title, description, image }) => (
			<SafetyCardContainer>
				<img
					src={urlUtils.appendQueryParams(image, `auto=compress&auto=format&w=160&h=160`)}
					alt={title}
				/>
				<SafetyTitle>{title}</SafetyTitle>
				<SafetyText>{description}</SafetyText>
			</SafetyCardContainer>
		))}
	</SafetyGridContainer>
);

export default SafetyGrid;

const SafetyCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`;

const SafetyTitle = styled.div`
	text-color: ${colors.darkBlue};
	font-weight: 700;
	font-size: 20px;
	line-height: 110%;
`;

const SafetyText = styled.div`
	text-color: ${colors.darkBlue};
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
`;
