import React from 'react';

import { Helmet } from 'react-helmet';

import theme from 'config/theme';
import Main from 'components/common/main';
import Footer from 'components/common/footer';
import Header from 'components/common/header';
import { PageMeta } from 'constants/page_meta';
import { TitleBar } from 'lib/TitleBar';
import ReportConversation from 'assets/images/safety/report-conversation.png';
import { ReactComponent as ReportListingSVG } from 'assets/images/safety/report-listing.svg';

import {
	PageContainer,
	BlueContentText,
	BoldBlueContentText,
	BlueInfoText,
	SafetyTitle,
	Divider,
	BulletedListItem,
	BulletedList,
} from './styles';
import SafetyGrid from './safety-grid/SafetyGrid';

const meta = PageMeta.getStatic('safety');
const { colors } = theme;

const Safety = () => {
	return (
		<>
			<Main>
				<Helmet>
					<title>{meta.title}</title>
					<meta name="description" content={meta.desc} />
				</Helmet>
				<Header searchable />
				<TitleBar title="Safety on DigsConnect"></TitleBar>
				<PageContainer>
					<SafetyTitle>Safety by design</SafetyTitle>
					<BlueContentText>
						Safety is a top priority at DigsConnect so we have designed our platform to have
						multiple layers of security. The safety of our users is not something we will compromise
						on so we shall continue to ensure that DigsConnect is a safe space.
					</BlueContentText>
					<SafetyGrid />
					<Divider />
					<SafetyTitle>Things to look out for</SafetyTitle>
					<BlueContentText>
						Although we do everything we can to make sure that landlords on DigsConnect are genuine,
						we also believe it is your responsibility to be aware of signs that might indicate
						fraudulent behaviour when interacting with landlords.
					</BlueContentText>
					<BoldBlueContentText>
						Here is a list of things to be on the lookout for
					</BoldBlueContentText>
					<BulletedList>
						<BulletedListItem>
							Landlords that don’t have a profile picture of a face or company logo.
						</BulletedListItem>
						<BulletedListItem>
							Landlords asking for payments before doing a viewing or providing a lease agreement.
						</BulletedListItem>
						<BulletedListItem>
							Landlords who don’t show the outside of their property.
						</BulletedListItem>
						<BulletedListItem>
							Landlords who ask for personal information without going for a viewing or providing a
							lease agreement.
						</BulletedListItem>
						<BulletedListItem>
							Landlords that stop communicating after receiving your personal information and / or
							payment.
						</BulletedListItem>
						<BulletedListItem>
							Landlords that don't want to arrange a viewing or provide a video tour of the listing,
							especially before payment.
						</BulletedListItem>
					</BulletedList>
					<Divider />
					<SafetyTitle>What to do if you’re worried about security</SafetyTitle>
					<BlueContentText>
						We’re here to help you every step of the way. If there is something you’re unsure about,
						we have multiple ways for you to get in touch with us.
					</BlueContentText>
					<BoldBlueContentText style={{ marginBottom: '16px' }}>
						Report this listing
					</BoldBlueContentText>
					<BlueInfoText>
						You can find a “Report this listing” link easily accessible on every listing. Simply
						click on the link and tell us your reason for reporting the listing. A member of our
						support team will get in touch with you to work things out.
					</BlueInfoText>
					<ReportListingSVG />
					<BoldBlueContentText style={{ marginBottom: '16px' }}>
						Report a conversation
					</BoldBlueContentText>
					<BlueInfoText>
						If you are chatting with a landlord on the DigsConnect Inbox and you feel uncomfortable
						with the conversation, simply click the icon at the top to report the conversation. A
						member of our support team will get in touch with you to assist you.
					</BlueInfoText>
					<img src={ReportConversation} width="570" />
					<BoldBlueContentText style={{ marginBottom: '16px' }}>
						Chat to your support agent
					</BoldBlueContentText>
					<BlueInfoText>
						Once you have made an enquiry and have a conversation going with a landlord, you are
						assigned a dedicated support agent who will be checking in with you throughout the
						process. If you are unsure of anything, just reach out to them and they will be more
						than happy to help out.
					</BlueInfoText>
					<BoldBlueContentText style={{ marginBottom: '16px' }}>Contact us</BoldBlueContentText>
					<BlueInfoText>
						Our{' '}
						<span>
							<a
								style={{
									cursor: 'pointer',
									color: colors.linkBlue,
									fontWeight: 700,
								}}
								href="https://www.digsconnect.com/contact-us"
								target={'_blank'}
								rel={'noopener noreferrer'}
							>
								Contact us
							</a>
						</span>{' '}
						page is always there if you need any other assistance. Just fill out the form so we know
						who is best suited from our team to help you and we'll get right back to you.
					</BlueInfoText>
				</PageContainer>
				<Footer />
			</Main>
		</>
	);
};

export default Safety;
